@import '~@styles/mixins.scss';

$mobile-width: 480px;
$tablet-small-width: 640px;
$tablet-width: 768px;
$tablet-width-course-catalog: 1000px;
$desktop-small-width: 950px;
$desktop-medium-width: 1400px;

$course-catalog-one-column-layout: 485px;
$course-catalog-two-column-layout: 1150px;
$course-catalog-one-column-layout-for-extra-wide: 650px;

$separator-border: 1px solid #e9e9e9;
$separator-border-dark: 1px solid #c8c8c8;

$menu-type: standard;

$form-field-wrapper-padding: 7px;

$color-background: #fff;

$color-black: #000;
$color-grey-10: #191919;
$color-grey-20: #333;
$color-grey-30: #4c4c4c;
$color-grey-40: #666;
$color-grey-45: #727272;
$color-grey-50: #7f7f7f;
$color-grey-60: #999;
$color-grey-70: #b2b2b2;
$color-grey-75: #bfbfbf;
$color-grey-80: #ccc;
$color-grey-85: #d8d8d8;
$color-grey-90: #e5e5e5;
$color-grey-95: #f2f2f2;
$color-grey-96: #f4f4f4;
$color-grey-97: #f7f7f7;
$color-grey-98: #f9f9f9;
$color-grey-99: #fcfcfc;
$color-white: #fff;
$color-gray-fields: #979797;

$color-grey-700: #3b3b3b;
$color-grey-600: #656565;

$color-hover: #d0ebff;
$color-points: #618da7;
$color-pdf: #006aac;
$color-blue: #078ada;
$color-green: green;
$color-red: red;
$color-orange: #ef591b;

$color-progressbar-fill: #f500a9;
$color-progressbar-fill-2: #f500a9;
$color-progressbar-fill-empty: #ccc;

$color-completed: #81c784;
$color-not-completed: #ef5350;

$event-default-background: #fff;
$event-default-border: #d1c8ff;
$event-default-text-color: white;

$event-expire-background: #d1304f;
$event-expire-border: #d1c8ff;
$event-expire-text-color: white;

$event-background: #4a25ff;
$event-border: #e0e0e0;
$event-text-color: black;

$text-size-sm: 0.875rem;
$font-size-ss: 0.7rem;
$font-size-s: 0.8rem;
$font-size-sm: 0.9rem;
$font-size-m: 1rem;
$font-size-ml: 1.15rem;
$font-size-l: 1.2rem;
$font-size-xl: 1.5rem;
$font-size-xxl: 2rem;


$font-color: $color-black;
$font-color-light: #6a6868;

$card-shadow: 5px 4px 18px rgba(100, 100, 100, 0.2);
$card-small-shadow: 1px 1px 8px rgba(100, 100, 100, 0.4);

$card-border: 1px solid $color-grey-80;
$card-separator: 1px solid $color-grey-95;

$border-radius-modal: 8px;

// $fonts: 'Quattrocento Sans', Helvetica, Arial, Sans-Serif;
